import { useContext } from 'react';
import { FiltersContext } from '../Navigation/Routes';
import FilterControls from './FilterControls.json';
import { Card, Flex } from '@aws-amplify/ui-react';
import { formatFilters, removeTags } from '../../utils/helpers';
import { handleSharedFiltersSave } from './handlers';
import ControlCard from './ControlCard';
const FilterConfirm = ({ onClose, setConfig, controlData }) => {
  const context = useContext(FiltersContext);
  const {
    viewType,
    filters,
    savedFiltersName,
    filtersNameInput, setFiltersNameInput
  } = context;


  const detaggedFilters = removeTags(viewType, filters, '');
  const displayedFilters = formatFilters(detaggedFilters);
  const view = viewType === 'basic'? 'Portfolio' : 'Category';


  const getControlValues = (page, control) => {
    if (controlData[page].data && controlData[page].data[control]) {
      return decodeURIComponent(controlData[page].data[control]).split(',');
    }
    return null;
  };

  
  const ControlList = ({ page, view, data, type }) => {
    if (type === "Filters"){
      return (
        <div className="control-filter-list">
          {data.map(([dispKey, dispValArr], index) => (
            <ControlCard key={`${dispKey}_key`} page={"Filters"} view={view} control={dispKey} data={dispValArr} type = {type} getControlValues={getControlValues} index={index} totalLength={data.length}/>
          ))}
        </div>
      );
    }
    else{
      return (
        <div className="control-filter-list">
          {Object.keys(data[page][view]).map((control, index) => (
            <ControlCard key={`${control}_${index}_key`} page={page} view={view} control={control} data={data} type = {type} getControlValues={getControlValues}/>
          ))}
        </div>
      );
    }

  };
  
  const renderData = (data, view, type) => {
    if (type === "Filters"){
      return(
        <div key={"Filters"} className='filter-container-flex'>
          <h2 className='filters-page-title'>{"FILTERS"}</h2>
          { data[0] ? <ControlList page={"Filters"} view={view} data={data} type = {type}/> : <p>No filters selected</p>}
        </div>
      ) 
    }
    else{
      return Object.keys(data).map((page) => (
        <div>
          <div key={page} className='full-control-container'>
            <h2 className='control-page-title'>{page.toUpperCase()}</h2>
            <div className='control-page-triangle'></div>
            <ControlList page={page} view={view} data={data} type={type} />          
          </div>
          <div className='filter-divider'></div>
        </div>
      ));
    }

  };
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className='popup-title'>{view.toUpperCase()} PROFILE SUMMARY </h2>
        <div className= "filters-popup">
          <div className='filters-container'>
            {renderData(FilterControls, view, "Controls")}
            {renderData(displayedFilters, view, "Filters")}
          </div>

        </div>
        <div className='filters-button-container'>
          <button className='filters-button-cancel' onClick={onClose}>CANCEL</button>
          <button className='filters-button-confirm' onClick={() =>{
            handleSharedFiltersSave(controlData, FilterControls, view, filters, viewType, filtersNameInput, setFiltersNameInput, savedFiltersName, setConfig);
            onClose()}}>CONFIRM</button>
        </div>
      </div>
    </div>
  );
};

export default FilterConfirm;